import React from 'react';
import Route from '@app/components/route';

import { Switch } from 'react-router-dom';

import routes from '.';

const ExplorePage = React.lazy(() => import('./explore-page'));
const BenefitPage = React.lazy(() => import('./benefit-page'));
const DealsPage = React.lazy(() => import('./deals-page'));
const SearchPage = React.lazy(() => import('./search-page'));

const Explore = () => (
  <Switch>
    <Route path={routes.deals} title="Featured deals">
      <DealsPage />
    </Route>

    <Route path={routes.benefit} title="Explore > Benefit details">
      <BenefitPage />
    </Route>

    <Route path={routes.search} title="Search results">
      <SearchPage />
    </Route>

    <Route path={routes.index} title="Explore">
      <ExplorePage />
    </Route>
  </Switch>
);

export default Explore;
