import React from 'react';
import useBreakpoint from 'utils/src/hooks/useBreakpoint';
import * as Icons from '@benepass/icons';
import * as Page from '../page';

import customTwMerge from '../../../utils/twMerge';
import SidebarButton from './sidebar-button';

import { ReactComponent as LogoBigWhite } from '../../../assets/logos/benepass-white-with-text.svg';
import { ReactComponent as LogoBigDark } from '../../../assets/logos/benepass-dark-with-text.svg';
import { ReactComponent as LogoSmallWhite } from '../../../assets/logos/benepass-white.svg';
import { ReactComponent as LogoSmallDark } from '../../../assets/logos/benepass-dark.svg';

import { useLocation, matchPath } from 'react-router-dom';
import { down, up } from 'styled-breakpoints';
import { motion } from 'framer-motion';

export type Layout = {
  route: string;
  className?: {
    root?: string;
  };
  layout?:
    | React.ComponentClass<{ children: React.ReactNode } & unknown>
    | React.ComponentType<{ children: React.ReactNode } & unknown>;
};

export type SidebarRoute = {
  key: string;
  children?: React.ReactNode;
  icon?: keyof typeof Icons;
  enabled: undefined | boolean;
  route: string;
  enabledRoutes: string[];
};

type Props = React.PropsWithChildren<{
  sidebarRoutes?: SidebarRoute[];
  sidebarFooter?: React.ReactNode;
  sidebarTheme?: 'dark' | 'light';
  layouts?: Layout[];
}>;

type SidebarProps = React.PropsWithChildren<{
  routes: SidebarRoute[];
  footer?: React.ReactNode;
  theme?: Props['sidebarTheme'];
}>;

type SidebarContentProps = SidebarProps & {
  isCollapsed: boolean;
};

export const DefaultLogo = ({ theme }: { theme: Props['sidebarTheme'] }) => (
  <div className="py-1">{theme === 'dark' ? <LogoSmallWhite /> : <LogoSmallDark />}</div>
);

const SidebarContent = ({ routes, footer, isCollapsed, theme, ...props }: SidebarContentProps) => {
  const location = useLocation();

  const sidebarLogo = React.useMemo(() => {
    const customLogo = React.Children.toArray(props.children).find(
      // eslint-disable-next-line
      (child) => React.isValidElement(child) && child.type === Sidebar.Logo
    );

    if (customLogo) return customLogo;
    /**
     * @notes Benepass' logo (for light and dark themes).
     */
    return <DefaultLogo theme={theme} />;
  }, [props.children, theme]);

  return (
    <>
      <div className={customTwMerge(isCollapsed ? 'px-3 md:px-4' : 'px-5 md:px-6')}>{sidebarLogo}</div>

      <div
        className={customTwMerge('flex flex-col mt-3 overflow-y-auto py-1', isCollapsed ? 'gap-2 px-2' : 'gap-0 px-3')}
      >
        {routes.map((route) => (
          <SidebarButton
            icon={route.icon}
            key={`sidebar_route_${route.key}`}
            theme={theme}
            active={
              (route.enabledRoutes || []).some((path) =>
                matchPath(location.pathname, {
                  path,
                  exact: true,
                })
              ) || undefined
            }
            to={route.route}
          >
            {route.children}
          </SidebarButton>
        ))}
      </div>

      {footer ? <div className="mt-auto">{footer}</div> : null}
    </>
  );
};

export const Sidebar = ({ routes, footer, theme = 'dark', children }: SidebarProps) => {
  const location = useLocation();
  const { isCollapsed, setCollapsed } = Page.usePageContainer();

  const toggleSidebar = React.useCallback(() => setCollapsed(!isCollapsed), [isCollapsed, setCollapsed]);
  const isMobile = useBreakpoint(down('sm'));

  const collapseButtonClassName = React.useMemo(() => {
    if (theme === 'light') return isCollapsed ? 'bg-indigo-100 text-grayscale-0' : 'bg-grayscale-0 text-grayscale-64';
    return isCollapsed ? 'bg-grayscale-0 text-grayscale-100' : 'text-grayscale-0';
  }, [theme, isCollapsed]);

  React.useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [isMobile, setCollapsed, location.pathname]);

  return (
    <>
      {isMobile ? (
        <header className="flex relative z-50">
          <div
            className={customTwMerge('flex w-full px-4 py-3', theme === 'dark' ? 'bg-indigo-100' : 'bg-grayscale-0')}
          >
            {theme === 'dark' ? <LogoBigWhite /> : <LogoBigDark />}

            <button type="button" className="ml-auto" onClick={toggleSidebar} aria-label="Toggle sidebar">
              <Icons.Menu className={theme === 'dark' ? 'text-grayscale-0' : 'text-grayscale-100'} size="24px" />
            </button>
          </div>

          <motion.div animate={{ left: isCollapsed ? -288 : 0 }} className="absolute">
            <div
              className={customTwMerge(
                'fixed top-0 bottom-0 h-fill-available',
                theme === 'dark' ? 'bg-indigo-100' : 'bg-grayscale-0'
              )}
            >
              <div className="pt-3 h-full flex flex-col" style={{ minWidth: 288 }}>
                <SidebarContent isCollapsed={isCollapsed} routes={routes} footer={footer} theme={theme}>
                  {children}
                </SidebarContent>
              </div>
            </div>
          </motion.div>
        </header>
      ) : (
        <motion.header
          animate={{ width: isCollapsed ? 72 : 288 }}
          transition={{ stiffness: 300, damping: 30, type: 'spring' }}
          className={customTwMerge(
            'sticky top-0 z-10',
            'border-r border-coolgray-20',
            'flex md:flex-col flex-shrink-0 h-screen',
            'pt-6',
            theme === 'dark' ? 'bg-indigo-100' : 'bg-grayscale-0'
          )}
        >
          <SidebarContent isCollapsed={isCollapsed} routes={routes} footer={footer} theme={theme}>
            {children}
          </SidebarContent>

          <button
            className={customTwMerge(
              'absolute right-2 top-7 z-20',
              'transition-transform',
              isCollapsed ? 'transform translate-x-5 rounded-full' : '',
              collapseButtonClassName
            )}
            type="button"
            onClick={toggleSidebar}
            aria-label="Toggle sidebar"
          >
            <Icons.AngleLeft
              size="16px"
              color="textCurrent"
              className={customTwMerge('transition-all', isCollapsed ? 'transform rotate-180' : '')}
            />
          </button>
        </motion.header>
      )}
    </>
  );
};

const DrawerShadow = () => {
  const { isCollapsed, setCollapsed } = Page.usePageContainer();
  const toggleSidebar = React.useCallback(() => setCollapsed(!isCollapsed), [isCollapsed, setCollapsed]);

  const isDesktop = useBreakpoint(up('md'));

  if (isDesktop || isCollapsed) return null;

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <div
      className="w-full bg-grayscale-100 bg-opacity-75 fixed top-0 bottom-0 right-0"
      style={{ width: 'calc(100vw)' }}
      role="button"
      onClick={toggleSidebar}
    />
  );
};

const Logo = ({ children }: React.PropsWithChildren) => <>{children}</>;

const DynamicMainContainer = ({
  layouts = [],
  sidebarRoutes: sidebarRoutesProp = [],
  sidebarTheme = 'dark',
  children,
  ...props
}: Props) => {
  const location = useLocation();

  const sidebarRoutes = React.useMemo(
    () => (sidebarRoutesProp || []).filter((value) => value?.enabled ?? false),
    [sidebarRoutesProp]
  );

  const customLayout = React.useMemo(
    () => layouts.find(({ route: path }) => matchPath(location.pathname, { path, exact: true })),
    [location.pathname, layouts]
  );

  React.useLayoutEffect(() => {
    window?.scrollTo(0, 0);
  }, [location.pathname]);

  const bodyChildren = React.useMemo(
    () =>
      React.Children.toArray(children).filter((child) => React.isValidElement(child) && child.type !== Sidebar.Logo),
    [children]
  );

  const sidebarChildren = React.useMemo(
    () =>
      React.Children.toArray(children).filter((child) => React.isValidElement(child) && child.type === Sidebar.Logo),
    [children]
  );

  if (customLayout?.layout) {
    const Comp = customLayout.layout || 'div';
    return <Comp>{bodyChildren}</Comp>;
  }

  return (
    <Page.Container className="flex flex-col md:flex-row min-h-screen md:min-h-0">
      <Sidebar routes={sidebarRoutes} footer={props.sidebarFooter} theme={sidebarTheme}>
        {sidebarChildren}
      </Sidebar>

      <main
        className={customTwMerge(
          'bg-grayscale-2 overflow-y-auto overflow-x-hidden flex-1',
          customLayout?.className?.root || ''
        )}
      >
        {bodyChildren}
        <DrawerShadow />
      </main>
    </Page.Container>
  );
};

Sidebar.Logo = Logo;
Sidebar.DefaultLogo = DefaultLogo;

export default DynamicMainContainer;
