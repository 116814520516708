import { useEffect, useMemo } from 'react';
import { GenericPageLoader } from 'react-components/src/ui/layout/page';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { PageError, Message } from 'react-components';
import { ErrorBoundary } from 'react-error-boundary';

import useAnalytics from 'utils/src/hooks/useAnalytics';
import LoadingOverlay from 'react-components/src/components/loading-overlay';
import SuspenseWrapper from 'react-components/src/components/suspense-wrapper';
import UnknownError from 'react-components/src/components/unknown-error';
import useLocalStorage from 'utils/src/hooks/useLocalStorage';
import MainLayout from '@app/components/layout/main-layout';
import PageContainer from '@app/components/page-container';

import { useCheckOnboardingQuery } from '@app/data/check-onboarding-query';
import { LogCategories, logHandler, errorHandler } from 'utils/src/Logs';
import { contactSupportMessage } from 'utils/src/Support';
import { useUserWorkspace } from '@hooks/use-user-workspace';
import { replaceRoutesParams } from 'utils/src/url-utils';
import { useViewerQuery } from '@app/data/viewer-query';
import { Helmet } from 'react-helmet';

import NewExpenseRoutes from './new-expense/routes';
import TransactionsRoutes from './transactions/routes';
import HomeRoutes from './home/routes';
import OnboardingRoutes from './onboarding/routes';
import AccountsRoutes from './accounts/routes';
import ExploreRoutes from './explore/routes';
import CardsRoutes from './cards/routes';
import SettingsRoutes from './settings/routes';
import MerchantsRoutes from './merchants/routes';
import WorkspacesRoutes from './workspaces/routes';

import FileUploadRoutes from './file-uploads/routes';
import routes from '.';

const { MessageTypes } = Message;

const Loader = () => {
  const [isCollapsed] = useLocalStorage('sidebar-collapse-state', false);

  return (
    <div className="flex flex-row">
      <div className="hidden md:block p-3 h-screen">
        <div className={`animate-pulse bg-grayscale-8 ${isCollapsed ? 'w-16' : 'w-72'} h-full rounded`} />
      </div>
      <div className="p-3 w-full h-screen">
        <div className="animate-pulse bg-grayscale-8 w-full h-full rounded" />
      </div>
    </div>
  );
};

const Routes = () => (
  <SuspenseWrapper loader={<LoadingOverlay />}>
    <ErrorBoundary FallbackComponent={() => <UnknownError />} onError={logHandler(LogCategories.UNKNOWN_GRAPHQL)}>
      <SuspenseWrapper loader={<Loader />}>
        <RoutesInner />
      </SuspenseWrapper>
    </ErrorBoundary>
  </SuspenseWrapper>
);

const MainRoutes = () => (
  <Switch>
    <SuspenseWrapper
      loader={
        <PageContainer>
          <GenericPageLoader />
        </PageContainer>
      }
    >
      <HomeRoutes />
      <TransactionsRoutes />
      <MerchantsRoutes />
      <AccountsRoutes />
      <ExploreRoutes />
      <CardsRoutes />
      <NewExpenseRoutes />
      <SettingsRoutes />
      <WorkspacesRoutes />
      <FileUploadRoutes />
    </SuspenseWrapper>
  </Switch>
);

const RoutesInner = () => {
  const location = useLocation();
  const { page, identify } = useAnalytics();
  const history = useHistory();

  const [selectedWorkspace] = useUserWorkspace();
  const viewer = useViewerQuery();
  const onboardings = useCheckOnboardingQuery();

  const previousUrl = useMemo(() => location.pathname, [location?.pathname]);

  useEffect(() => {
    page(location.pathname, {
      search: location.search,
      previousPath: previousUrl,
    });
  }, [previousUrl, page, location]);

  useEffect(() => {
    identify(viewer?.apiID, {
      email: viewer?.email,
      name: viewer?.fullName,
    });
  }, [identify, viewer]);

  useEffect(() => {
    const firstIncompleteOnboarding = onboardings.find(({ isComplete }) => !isComplete);

    if (firstIncompleteOnboarding) {
      const route = (() => {
        switch (firstIncompleteOnboarding.apiID) {
          case 'userOnboarding':
            return routes.onboarding.index;

          default:
            return replaceRoutesParams(routes.onboarding.genericOnboarding, { id: firstIncompleteOnboarding.apiID });
        }
      })();

      history.replace(route);
    }
    // @notes
    //  Even though `selectedWorkspace` is not a dependency we want to re-run this effect
    //  when the selected workspace changes. This is because the onboarding query relies
    //  on the selected workspace to fetch the correct onboarding data.
  }, [onboardings, history, selectedWorkspace]);

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <PageError title="Server Unreachable">
          <Message type={MessageTypes.ERROR}>Something is wrong on Benepass Servers. {contactSupportMessage}</Message>
        </PageError>
      )}
      onError={errorHandler(LogCategories.UNKNOWN_ERROR)}
    >
      <Helmet>
        <script src="/assets/customer-io/customer-io.js" async />
      </Helmet>

      <Switch>
        <MainLayout>
          <OnboardingRoutes />
          <MainRoutes />
        </MainLayout>
      </Switch>
    </ErrorBoundary>
  );
};

export default Routes;
